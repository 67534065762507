<template>
  <div class="text-black mb-3">
    <div>
      <div class="mb-0 text-blue-800 text-base flex justify-end">
        <div class="text-black fs-14 mr-3 cursor-pointer fw-600" @click="showModal(true)">
          <svg
            class="mr-1"
            width="16"
            height="16"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.039 5.13579L8.1 3.06528V11.6984C8.1 11.9372 8.19482 12.1662 8.3636 12.335C8.53239 12.5038 8.76131 12.5987 9 12.5987C9.23869 12.5987 9.46761 12.5038 9.6364 12.335C9.80518 12.1662 9.9 11.9372 9.9 11.6984V3.06528L11.961 5.13579C12.0447 5.22017 12.1442 5.28714 12.2539 5.33284C12.3636 5.37855 12.4812 5.40208 12.6 5.40208C12.7188 5.40208 12.8364 5.37855 12.9461 5.33284C13.0558 5.28714 13.1553 5.22017 13.239 5.13579C13.3234 5.05211 13.3903 4.95254 13.436 4.84284C13.4817 4.73314 13.5052 4.61547 13.5052 4.49663C13.5052 4.37779 13.4817 4.26013 13.436 4.15043C13.3903 4.04073 13.3234 3.94116 13.239 3.85747L9.639 0.256576C9.55341 0.174619 9.45248 0.110375 9.342 0.067529C9.12288 -0.0225097 8.87712 -0.0225097 8.658 0.067529C8.54752 0.110375 8.44659 0.174619 8.361 0.256576L4.761 3.85747C4.67709 3.94141 4.61052 4.04106 4.56511 4.15072C4.51969 4.26039 4.49632 4.37793 4.49632 4.49663C4.49632 4.61534 4.51969 4.73288 4.56511 4.84254C4.61052 4.95221 4.67709 5.05186 4.761 5.13579C4.84491 5.21973 4.94454 5.28631 5.05418 5.33173C5.16382 5.37716 5.28133 5.40054 5.4 5.40054C5.51867 5.40054 5.63618 5.37716 5.74582 5.33173C5.85546 5.28631 5.95509 5.21973 6.039 5.13579ZM17.1 8.99776C16.8613 8.99776 16.6324 9.0926 16.4636 9.26142C16.2948 9.43025 16.2 9.65922 16.2 9.89798V15.2993C16.2 15.5381 16.1052 15.7671 15.9364 15.9359C15.7676 16.1047 15.5387 16.1996 15.3 16.1996H2.7C2.4613 16.1996 2.23239 16.1047 2.0636 15.9359C1.89482 15.7671 1.8 15.5381 1.8 15.2993V9.89798C1.8 9.65922 1.70518 9.43025 1.5364 9.26142C1.36761 9.0926 1.13869 8.99776 0.9 8.99776C0.661305 8.99776 0.432387 9.0926 0.263604 9.26142C0.0948211 9.43025 0 9.65922 0 9.89798V15.2993C0 16.0156 0.284464 16.7025 0.790812 17.209C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.209C17.7155 16.7025 18 16.0156 18 15.2993V9.89798C18 9.65922 17.9052 9.43025 17.7364 9.26142C17.5676 9.0926 17.3387 8.99776 17.1 8.99776Z"
              fill="#03267A"
            />
          </svg>
          Upload Phiếu
        </div>
      </div>
      <div v-loading="loading && !update.show">
        <el-table
          :data="decalarations"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
          row-class-name="text-black text-sm"
          header-cell-class-name="text-black text-base font-semibold"
          class="cursor-pointer"
          @row-click="goToViewDetail"
        >
          <el-table-column :formatter="getDateTime" label="Ngày tạo phiếu"></el-table-column>
          <el-table-column prop="PhysicianName" label="Bác sĩ kê đơn"></el-table-column>
          <el-table-column
            prop="NurseName"
            label="Điều dưỡng nhận"
            class-name="text-center"
            width="240px"
          ></el-table-column>
        </el-table>
        <div class="flex justify-content-end mt-3">
          <el-pagination
            v-if="decalarations"
            background
            layout="prev, pager, next"
            :total="decalarationsPaging.total"
            :page-size="parseInt(decalarationsPaging.per_page)"
            @current-change="handleCurrentPage"
          ></el-pagination>
        </div>
      </div>
    </div>

    <ModalUploadFile @onSubmit="onUpload($event)" title="Upload Phiếu" />

    <div
      class="modal fade"
      id="ModalChiTietDichVuYTe"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ModalChiTietDichVuYTeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <div
              class="fs-24 fw-700 txt-grey-900 text-center w-100 uppercase"
            >{{ update.show ? "Chỉnh sửa phiếu" : "Chi tiết phiếu" }}</div>
            <div class="flex flex-end items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 cursor-pointer"
                @click="onUpdate"
                v-if="
                  viewingFileDetail &&
                  viewingFileDetail.CreatedBy &&
                  viewingFileDetail.CreatedBy == $user.id
                "
              >
                <path
                  d="M21.8749 12.4999C21.5987 12.4999 21.3337 12.6096 21.1383 12.805C20.943 13.0003 20.8333 13.2653 20.8333 13.5415V19.7915C20.8333 20.0678 20.7235 20.3327 20.5282 20.5281C20.3328 20.7234 20.0679 20.8332 19.7916 20.8332H5.20825C4.93198 20.8332 4.66703 20.7234 4.47168 20.5281C4.27633 20.3327 4.16659 20.0678 4.16659 19.7915V5.20819C4.16659 4.93192 4.27633 4.66697 4.47168 4.47162C4.66703 4.27627 4.93198 4.16652 5.20825 4.16652H11.4583C11.7345 4.16652 11.9995 4.05678 12.1948 3.86142C12.3902 3.66607 12.4999 3.40112 12.4999 3.12486C12.4999 2.84859 12.3902 2.58364 12.1948 2.38829C11.9995 2.19294 11.7345 2.08319 11.4583 2.08319H5.20825C4.37945 2.08319 3.58459 2.41243 2.99854 2.99848C2.41249 3.58453 2.08325 4.37939 2.08325 5.20819V19.7915C2.08325 20.6203 2.41249 21.4152 2.99854 22.0012C3.58459 22.5873 4.37945 22.9165 5.20825 22.9165H19.7916C20.6204 22.9165 21.4152 22.5873 22.0013 22.0012C22.5873 21.4152 22.9166 20.6203 22.9166 19.7915V13.5415C22.9166 13.2653 22.8068 13.0003 22.6115 12.805C22.4161 12.6096 22.1512 12.4999 21.8749 12.4999ZM6.24992 13.2915V17.7082C6.24992 17.9845 6.35967 18.2494 6.55502 18.4448C6.75037 18.6401 7.01532 18.7499 7.29159 18.7499H11.7083C11.8453 18.7506 11.9812 18.7244 12.1082 18.6725C12.2351 18.6207 12.3505 18.5443 12.4478 18.4478L19.6562 11.229L22.6145 8.33319C22.7121 8.23635 22.7896 8.12114 22.8425 7.99421C22.8954 7.86727 22.9226 7.73112 22.9226 7.59361C22.9226 7.45609 22.8954 7.31994 22.8425 7.193C22.7896 7.06607 22.7121 6.95086 22.6145 6.85402L18.1978 2.38527C18.101 2.28764 17.9858 2.21014 17.8589 2.15726C17.7319 2.10438 17.5958 2.07715 17.4583 2.07715C17.3207 2.07715 17.1846 2.10438 17.0577 2.15726C16.9307 2.21014 16.8155 2.28764 16.7187 2.38527L13.7812 5.33319L6.552 12.5519C6.45546 12.6493 6.37908 12.7647 6.32724 12.8916C6.2754 13.0185 6.24913 13.1544 6.24992 13.2915ZM17.4583 4.59361L20.4062 7.54152L18.927 9.02069L15.9791 6.07277L17.4583 4.59361ZM8.33325 13.7186L14.5103 7.54152L17.4583 10.4894L11.2812 16.6665H8.33325V13.7186Z"
                  fill="black"
                />
              </svg>

              <button
                type="button"
                class="close bg-white border-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="fs-30 fw-700">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body pt-0">
            <div class="row" v-if="!update.show">
              <div class="col-4 mb-2">
                <p class="fs-14 fw-700 txt-grey-900">Ngày giờ:</p>
              </div>
              <div class="col-8 mb-2">
                <p class="fs-14 txt-grey-900">
                  {{
                  viewingFileDetail &&
                  viewingFileDetail.VisitedAt &&
                  moment(viewingFileDetail.VisitedAt).format("HH:mm:ss DD/MM/YYYY")
                  }}
                </p>
              </div>
              <div class="col-4">
                <p class="fs-14 fw-700 txt-grey-900">Bác sĩ kê đơn:</p>
              </div>
              <div class="col-8">
                <p
                  class="fs-14 txt-grey-900"
                >{{ (viewingFileDetail && viewingFileDetail.PhysicianName) || '' }}</p>
              </div>
              <div class="col-4 mb-2">
                <p class="fs-14 fw-700 txt-grey-900">Điều dưỡng nhận:</p>
              </div>
              <div class="col-8 mb-2">
                <p
                  class="fs-14 txt-grey-900"
                >{{ (viewingFileDetail && viewingFileDetail.NurseName) || '' }}</p>
              </div>
            </div>

            <div v-else>
              <div class="mb-3 row">
                <div class="col-md-4">
                  <label class="mb-1 fs-16 txt-grey-900 fw-700 mt-2 required">Ngày:</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="formatVisitedAt"
                    type="datetime-local"
                    class="form-control fw-500 txt-grey-900"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <div class="col-md-4">
                  <label class="fs-16 fw-700 txt-grey-900">Bác sĩ kê đơn</label>
                </div>
                <div class="col-md-8">
                  <el-select
                    @change="getDoctor(null)"
                    v-model="update.form.PhysicianId"
                    filterable
                    remote
                    reserve-keyword
                    allow-create
                    placeholder="Tìm kiếm tên bác sĩ"
                    class="w-100"
                    :remote-method="getDoctor"
                    :loading="loadingDoctor"
                  >
                    <el-option
                      v-for="item in doctors"
                      :key="item.id + 'dtr'"
                      :label="item.name"
                      :value="item.user_id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="mb-3 row">
                <div class="col-md-4">
                  <label class="fs-16 fw-700 txt-grey-900">Điều dưỡng nhận</label>
                </div>
                <div class="col-md-8">
                  <el-select
                    @change="getDoctor(null)"
                    v-model="update.form.NurseId"
                    filterable
                    remote
                    reserve-keyword
                    allow-create
                    placeholder="Tìm kiếm tên bác sĩ/ điều dưỡng"
                    class="w-100"
                    :remote-method="getDoctor"
                    :loading="loadingDoctor"
                  >
                    <el-option
                      v-for="item in doctors"
                      :key="item.id + 'dtr'"
                      :label="item.name"
                      :value="item.user_id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div class="text-center" v-if="!update.show">
              <a
                href="javascript:;"
                class="txt-pri fs-14 text-decoration-underline"
                @click="
                  openFile(viewingFileDetail && viewingFileDetail.FileUrl)
                "
              >Xem phiếu</a>
            </div>
            <div class="flex mt-2" v-else>
              <div class="w-75">
                <p
                  class="fs-14 fw-700 txt-grey-900"
                >{{ selectedFile ? selectedFile.name : appUtils.getFilename(viewingFileDetail.FileUrl) }}</p>
              </div>
              <div class="w-25 text-right">
                <a
                  href="javascript:;"
                  class="txt-grey-900 fs-14 text-decoration-underline"
                  @click="openSelectFile"
                >Thay đổi</a>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="update.show">
            <button type="button" class="btn btn-muted" @click="onCancelUpdate">Huỷ</button>
            <button
              :disabled="loading"
              type="button"
              class="btn bg-pri bd-pri text-white"
              @click="onSubmitUpdate"
            >
              <span
                class="spinner-border spinner-border-sm mr-1 fs-12"
                role="status"
                v-if="loading"
              ></span>
              <svg
                v-else
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-1"
              >
                <path
                  d="M5.34961 3.61963H16.5696C17.067 3.61968 17.5465 3.80508 17.9146 4.13963L20.6946 6.66663C20.9009 6.85406 21.0657 7.08254 21.1785 7.33741C21.2913 7.59228 21.3496 7.86791 21.3496 8.14663V19.6196C21.3496 20.1501 21.1389 20.6588 20.7638 21.0338C20.3888 21.4089 19.88 21.6196 19.3496 21.6196H5.34961C4.81918 21.6196 4.31047 21.4089 3.9354 21.0338C3.56032 20.6588 3.34961 20.1501 3.34961 19.6196V5.61963C3.34961 5.0892 3.56032 4.58049 3.9354 4.20542C4.31047 3.83034 4.81918 3.61963 5.34961 3.61963V3.61963ZM5.34961 5.61963V19.6196H19.3496V8.14663L16.5696 5.61963H5.34961ZM9.34961 13.6196H15.3496C15.88 13.6196 16.3888 13.8303 16.7638 14.2054C17.1389 14.5805 17.3496 15.0892 17.3496 15.6196V19.6196C17.3496 20.1501 17.1389 20.6588 16.7638 21.0338C16.3888 21.4089 15.88 21.6196 15.3496 21.6196H9.34961C8.81918 21.6196 8.31047 21.4089 7.9354 21.0338C7.56032 20.6588 7.34961 20.1501 7.34961 19.6196V15.6196C7.34961 15.0892 7.56032 14.5805 7.9354 14.2054C8.31047 13.8303 8.81918 13.6196 9.34961 13.6196ZM9.34961 15.6196V19.6196H15.3496V15.6196H9.34961ZM16.3496 6.61963C16.6148 6.61963 16.8692 6.72499 17.0567 6.91252C17.2443 7.10006 17.3496 7.35441 17.3496 7.61963V10.6196C17.3496 10.8848 17.2443 11.1392 17.0567 11.3267C16.8692 11.5143 16.6148 11.6196 16.3496 11.6196C16.0844 11.6196 15.83 11.5143 15.6425 11.3267C15.455 11.1392 15.3496 10.8848 15.3496 10.6196V7.61963C15.3496 7.35441 15.455 7.10006 15.6425 6.91252C15.83 6.72499 16.0844 6.61963 16.3496 6.61963ZM8.34961 6.61963H13.3496C13.6148 6.61963 13.8692 6.72499 14.0567 6.91252C14.2443 7.10006 14.3496 7.35441 14.3496 7.61963V10.6196C14.3496 10.8848 14.2443 11.1392 14.0567 11.3267C13.8692 11.5143 13.6148 11.6196 13.3496 11.6196H8.34961C8.08439 11.6196 7.83004 11.5143 7.6425 11.3267C7.45497 11.1392 7.34961 10.8848 7.34961 10.6196V7.61963C7.34961 7.35441 7.45497 7.10006 7.6425 6.91252C7.83004 6.72499 8.08439 6.61963 8.34961 6.61963ZM9.34961 9.61963H12.3496V8.61963H9.34961V9.61963Z"
                  fill="white"
                />
              </svg>
              Lưu
            </button>
          </div>
        </div>
      </div>
    </div>

    <input
      ref="selectFileUpdateDichVuYTe"
      class="d-none"
      name="file-upload"
      type="file"
      @change="onSelectFileUpdateDichVuYTe"
      accept="jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .ppt, .pptx, .heic"
    />
  </div>
</template>

<script>
import moment from 'moment'
import ModalUploadFile from './ModalUploadFile'
import appUtils from '../../../utils/appUtils'
import { mapState } from 'vuex'

export default {
  name: 'DichVuYTeList',
  components: { ModalUploadFile },
  data () {
    const defaultForm = {
      PersonId: null,
      MedicalRecordId: null,
      VisitedAt: window.moment().format('YYYY-MM-DD HH:mm:ss'),
      NutritionType: null,
      PhysicianId: null,
      NurseId: null,
      FileUrl: null
    }

    return {
      loading: false,
      decalarations: null,
      decalarationsPaging: null,
      moment,
      defaultForm,
      update: {
        form: defaultForm,
        show: false
      },
      viewingFileDetail: null,
      selectedFile: null,
      appUtils,
      modalUpdateSaved: false
    }
  },
  watch: {
    '$route.query.page' () {
      // this.handleCurrentPage()
    }
  },
  computed: {
    ...mapState({
      person: state => state.person
    }),
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain
    }),
    formatVisitedAt: {
      get () {
        return this.update.form.VisitedAt
          ? appUtils.formatInputDateTime(this.update.form.VisitedAt)
          : ''
      },
      set (newDate) {
        this.update.form.VisitedAt = newDate
        return newDate
      }
    }
  },
  mounted () {
    let self = this
    self.getDoctor()
    self.getDecalarations()
    self.$nextTick(function () {
      window.$('#ModalChiTietDichVuYTe').on('hidden.bs.modal', function (e) {
        self.getDecalarations()
        self.viewingFileDetail = null
        self.onCancelUpdate()
      })
      window.$('#ModalChiTietDichVuYTe').on('hide.bs.modal', function (e) {
        if (self.update.show && !self.modalUpdateSaved) {
          if (
            !confirm(
              'Các thay đổi có thể chưa được lưu. Bạn có muốn lưu không?'
            )
          ) {
            return true
          } else self.onSubmitUpdate()
        } else {
          self.modalUpdateSaved = false
        }
      })
    })
  },
  methods: {
    handleCurrentPage (page) {
      let query = Object.assign({}, this.$route.query)
      query.page = page
      this.$router.replace({ query }).catch(_ => {})
      this.getDecalarations(page)
    },
    async onUpload (event) {
      if (isNaN(event.NurseId)) {
        event.NurseName = event.NurseId
        event.NurseId = null
      }
      if (isNaN(event.PhysicianId)) {
        event.PhysicianName = event.PhysicianId
        event.PhysicianId = null
      }
      const formData = new FormData()
      for (var key in event) {
        formData.append(key, event[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postDecalaration(formData)
        .then(r => {
          this.$router
            .replace({ query: { tab: this.$route.query.tab } })
            .catch(_ => {})
          this.getDecalarations()
          this.$toast.open({
            message: 'Upload file thành công',
            type: 'success'
          })
        })
    },
    showModal (show) {
      window.$('#ModalUploadPhieuDichVuYTe').modal(show ? 'show' : 'hide')
    },
    async getDecalarations (currentPage = 1) {
      let id = this.$route.params.id
      let query = Object.assign({}, this.$route.query)

      if (query?.page) {
        currentPage = query.page
      }

      if (!id) {
        return
      }
      this.loading = true
      const params = {
        MedicalRecordId: id,
        PersonId: this.person.id,
        page: currentPage,
        sort: 'visited_at',
        sort_by: 'desc'
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getDecalaration(params)
        .then(r => {
          this.decalarations = r.data.data
          this.decalarationsPaging = r.data.meta
        })
        .finally(() => {
          this.loading = false
        })
    },
    goToViewDetail (row) {
      this.onViewFile(row)
    },
    download (pdf) {
      window.open(pdf)
    },
    getDateTime (row) {
      if (row.VisitedAt) {
        return row.VisitedAt ? appUtils.formatDateTimeFull(row.VisitedAt) : ''
      } else {
        return row.CreatedAt ? appUtils.formatDateTimeFull(row.CreatedAt) : ''
      }
    },
    getDoctor (search = null) {
      if (this.$globalClinic) {
        if (this.$globalClinic?.org_id) {
          this.getOrgDoctor(search)
        } else {
          this.getClinicDoctor(search)
        }
      }
    },
    async getOrgDoctor (search = null) {
      const params = {
        limit: 50
      }
      if (search) {
        params.search = search
      }
      this.loadingDoctor = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getOrgDoctor(this.$globalClinic?.org_id, params)
        .then(r => {
          this.doctors = r.data
          const hasMe = this.doctors.find(d => d.user_id === this.$user.id)
          if (!hasMe && this.$user.doctor) this.doctors.push(this.$user.doctor)
          this.doctorPaging = r.meta
        })
        .finally(() => {
          this.loadingDoctor = false
        })
    },
    async getClinicDoctor (search) {
      const params = {
        limit: 50
      }
      if (search) {
        params.search = search
      }
      this.loadingDoctor = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getClinicDoctor(this.$globalClinic?.id, params)
        .then(r => {
          this.doctors = r.data.data
          this.doctors.map(d => {
            d.name = d.doctor?.name
            d.user_id = d.doctor?.user_id
          })
          const hasMe = this.doctors.find(
            d => d.doctor_id === this.$user?.doctor.id
          )
          if (!hasMe && this.$user.doctor) this.doctors.push(this.$user.doctor)
          this.doctorPaging = {
            count: r.data.count,
            current_page: r.data.current_page,
            last_page: r.data.last_page,
            per_page: r.data.per_page,
            total: r.data.total
          }
        })
        .finally(() => {
          this.loadingDoctor = false
        })
    },
    onUpdate () {
      let item = { ...this.viewingFileDetail }
      this.modalUpdateSaved = false
      this.update = {
        form: {
          ID: item.ID,
          VisitedAt:
            (item.VisitedAt &&
              moment(item.VisitedAt).format('YYYY-MM-DD HH:mm:ss')) ||
            moment().format('YYYY-MM-DD HH:mm:ss'),
          FileUrl: item.FileUrl,
          NurseId: item.NurseId || item.NurseName,
          PhysicianId: item.PhysicianId || item.PhysicianName,
          MedicalRecordId: item.MedicalRecordId
        },
        show: true
      }
    },
    onShowModaViewFile (show) {
      window.$('#ModalChiTietDichVuYTe').modal(show ? 'show' : 'hide')
    },
    onViewFile (item) {
      if (!item) return
      this.viewingFileDetail = item
      this.onShowModaViewFile(true)
    },
    openFile (url) {
      if (!url) return
      window.open(url)
    },
    onCancelUpdate () {
      this.update = {
        id: null,
        form: this.defaultForm,
        show: false
      }
      this.selectedFile = null
    },
    async onSubmitUpdate () {
      if (!this.update.form.ID) return
      if (!this.update.form.VisitedAt) {
        alert('Vui lòng nhập ngày')
        return
      }
      if (isNaN(this.update.form.NurseId)) {
        this.update.form.NurseName = this.update.form.NurseId
        this.update.form.NurseId = 0
      }
      if (isNaN(this.update.form.PhysicianId)) {
        this.update.form.PhysicianName = this.update.form.PhysicianId
        this.update.form.PhysicianId = 0
      }
      const formData = new FormData()
      const data = (({
        VisitedAt,
        PhysicianId,
        NurseId,
        PhysicianName,
        NurseName,
        MedicalRecordId
      }) => ({
        VisitedAt: appUtils.formatInputDateTimeUTC(VisitedAt),
        PhysicianId,
        NurseId,
        PhysicianName,
        NurseName,
        MedicalRecordId
      }))(this.update.form)

      if (this.selectedFile instanceof File) {
        data.file = this.selectedFile
      }

      for (const key in data) {
        if (data[key]) formData.append(key, data[key])
      }
      this.modalUpdateSaved = true
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .putDecalaration(this.update.form.ID, formData)
        .then(r => {
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
          this.viewingFileDetail = r.data.data
          this.onCancelUpdate()

          let query = Object.assign({}, this.$route.query)
          if (query?.page) {
            this.getDecalarations(query.page)
          } else {
            this.getDecalarations()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    openSelectFile () {
      this.$refs.selectFileUpdateDichVuYTe.click()
      this.$refs.selectFileUpdateDichVuYTe.value = null
    },
    onSelectFileUpdateDichVuYTe (event) {
      this.selectedFile = event.target.files[0]
      this.$refs.selectFileUpdateDichVuYTe.value = null
    },
    async getShowAnesthesia (id) {
      this.loading = true
      const resp = await this.$rf
        .getRequest('DoctorRequest')
        .getShowAnesthesia(id)
        .then(r => {
          return r.data
        })
        .finally(() => {
          this.loading = false
        })
      return resp
    }
  }
}
</script>

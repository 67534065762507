<template>
  <div v-loading="loading">
    <div class="size-export" ref="viewDetail">
      <ViewDetail
        :form="form"
        :isEdit="false"
        :departments="departments"
        :attachments="attachments"
        :medical_record_files="medical_record_files"
        :person="person"
        :treat_doctor="treat_doctor"
        :ctaExport="ctaExport"
        @setIsLoading="setIsLoading"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ViewDetail from '../CardMaster/ViewDetail'
import { mixinPrint } from '../../../utils/mixinPrint'
import { WarningIcon } from '@/components/Icon'
import appUtils from '../../../utils/appUtils'
import ImageBox from '@/components/Common/Image.vue'

const setFormDefault = (value, person) => {
  return {
    ...value,
    visit_reasons: value.visit_reasons || '',
    pathological: value.pathological || '',
    anamnesis_self: value.anamnesis_self || '',
    anamnesis_family: value.anamnesis_family || '',
    exam_body: value.exam_body || '',
    exam_wound_desc: value.exam_wound_desc || '',
    laboratory_test: value.laboratory_test || '',
    exam_summary: value.exam_summary || '',
    exam_prognosis: value.exam_prognosis || '',
    treatment_direction: value.treatment_direction || '',
    clinical_progress: value.clinical_progress || '',
    laboratory_test_summary: value.laboratory_test_summary || '',
    discharge_status: value.discharge_status || '',
    treatment_direction_next: value.treatment_direction_next || '',
    insurance_number: person?.insurance_number || '',
    insurance_start_date: person?.insurance_start_date || '',
    insurance_expire: person?.insurance_expire || '',
    person
  }
}

export default {
  name: 'TreatmentCardVersion',
  mixins: [mixinPrint],
  components: {
    ViewDetail,
    WarningIcon,
    ImageBox
  },
  props: [
    'medicalRecordMain',
    'medical_record_code',
    'treat_doctor',
    'getBADT',
    'person',
    'ctaPrint'
  ],
  data () {
    return {
      form: {},
      ctaExport: 0,
      departments: [
        {
          department: this.$globalClinic?.name || null,
          start_time: null,
          days: null
        },
        {
          department: null,
          start_time: null,
          days: null
        }
      ],
      attachments: [],
      attachmentsUpdate: [],
      medical_record_files: [
        {
          type: 'X - quang',
          count: ''
        },
        {
          type: 'CT Scanner',
          count: ''
        },
        {
          type: 'Siêu âm',
          count: ''
        },
        {
          type: 'Xét nghiệm',
          count: ''
        },
        {
          type: 'Khác',
          count: ''
        }
      ],
      loading: false,
      updateByInfo: null
    }
  },
  watch: {
    medicalRecordMain () {
      this.mapData()
    },
    getBADT (data) {
      if (data?.created_at && !this.departments[0].start_time) {
        this.departments[0].start_time = data.created_at
      }
    },
    ctaPrint () {
      this.handleExportPdf()
    }
  },
  computed: {},
  mounted () {},
  methods: {
    mapData () {
      if (this.medicalRecordMain) {
        this.updateByInfo = this.medicalRecordMain?.update_by_info
        this.form = setFormDefault(this.medicalRecordMain, this.person)

        if (!this.form.admission_hospital_number) {
          this.form.admission_hospital_number = this.getBADT?.ref_id
        }
      } else {
        this.form = setFormDefault({}, this.person)
        this.form.admission_hospital_number = this.getBADT?.ref_id
      }

      this.form.insurance_start_date = this.form?.insurance_start_date
        ? this.formatDate(this.form.insurance_start_date)
        : ''
      this.form.insurance_expire = this.form?.insurance_expire
        ? this.formatDate(this.form.insurance_expire)
        : ''
      this.form.reception_at = this.form?.reception_at
        ? this.formatDate(this.form.reception_at)
        : this.form?.person?.admission_hospital_date
          ? this.formatDate(this.form?.person?.admission_hospital_date)
          : ''
      this.form.discharge_at = this.form?.discharge_at
        ? this.formatDate(this.form.discharge_at)
        : ''
      this.form.discharge_at_diagnose = this.form?.discharge_at_diagnose
        ? this.formatDate(this.form.discharge_at_diagnose)
        : ''
      this.form.profile_delivered_at = this.form?.profile_delivered_at
        ? this.formatDate(this.form.profile_delivered_at)
        : ''
      this.form.profile_received_at = this.form?.profile_received_at
        ? this.formatDate(this.form.profile_received_at)
        : ''

      if (this.form?.inouts && this.form?.inouts.length > 0) {
        if (this.form?.inouts.length > 1) {
          this.departments = this.form?.inouts
        } else {
          this.departments = [
            ...this.form?.inouts,
            {
              department: '',
              start_time: '',
              days: null
            }
          ]
        }
      } else {
        this.departments = [
          {
            department: this.medicalRecordMain?.in_department_name || '',
            start_time: this.medicalRecordMain?.in_department_time || '',
            days: this.medicalRecordMain?.in_department_dates || null
          },
          {
            department: this.medicalRecordMain?.change_department_name_2 || '',
            start_time: this.medicalRecordMain?.change_department_time_2 || '',
            days: this.medicalRecordMain?.change_department_dates_2 || null
          }
        ]

        if (this.medicalRecordMain?.change_department_name_3) {
          this.departments.push({
            department: this.medicalRecordMain?.change_department_name_3 || '',
            start_time: this.medicalRecordMain?.change_department_time_3 || '',
            days: this.medicalRecordMain?.change_department_dates_3 || null
          })
        }

        if (this.medicalRecordMain?.change_department_name_4) {
          this.departments.push({
            department: this.medicalRecordMain?.change_department_name_4 || '',
            start_time: this.medicalRecordMain?.change_department_time_4 || '',
            days: this.medicalRecordMain?.change_department_dates_4 || null
          })
        }
      }

      _.forEach(this.departments, value => {
        if (value.start_time) {
          value.start_time = this.formatDate(value.start_time)
        }
      })

      this.attachments = this.form?.attachments || []

      if (this.form?.files && this.form?.files.length > 0) {
        this.medical_record_files = this.form?.files
      }
    },
    close () {
      this.editForm(false)
      this.mapData()
    },

    formatDate (date) {
      if (!date) return ''
      return window.moment(date).format('YYYY-MM-DD HH:mm')
    },
    editForm (status) {
      this.isEdit = status
    },
    setIsLoading (value) {
      this.loading = value
    },
    async handleExportPdf () {
      this.loading = true
      this.$emit('onLoading', true)
      // this.ctaExport++

      await this.exportPdf({
        refDocument: this.$refs.viewDetail,
        fileName: `BenhAnUngBuou_BA_${this.medical_record_code}_`,
        path: 'bavv',
        type: 'bavv',
        type_id: 'bavv_id',
        appendStyle: true
      }).finally(() => {
        this.loading = false
        this.$emit('onLoading', false)
      })
    },
    handleShowVersion () {
      const params = {
        medical_record_id: this.medicalRecordMain?.medical_record_id
      }
      this.$store.dispatch('emrStore/getEMRVersion', params)
      this.$eventBus.$emit('onShowEMRVersion', true)
    },
    async putSyncEMR () {
      if (!this.getBADT?.ref_id) return

      try {
        this.loading = true
        await this.$rf
          .getRequest('DoctorRequest')
          .putSyncEMR(this.getBADT.ref_id)

        this.$toast.open({
          message: this.$t('Cập nhật thông tin bệnh án thành công'),
          type: 'success'
        })

        this.handleGoToEMR()
      } catch (error) {
        this.$toast.open({
          message: this.$t('Có lỗi trong quá trình đồng bộ'),
          type: 'warning'
        })

        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleSyncEmr () {
      this.$confirm('Vui lòng xác nhận để đồng bộ thông tin bệnh án từ HIS', {
        confirmButtonText: 'Xác nhận'
      })
        .then(_ => {
          this.putSyncEMR()
        })
        .catch(_ => {})
    },
    handleGoToEMR () {
      this.$router.replace({
        name: 'ERMTreeView',
        params: {
          id: this.medicalRecordMain?.medical_record_id
        }
      })
    },
    getImageURL (avatar) {
      if (avatar.includes('https')) return avatar
      return appUtils.getImageURL(avatar)
    },
    getDate () {
      if (!this.medicalRecordMain?.updated_at) return

      return window
        .moment(this.medicalRecordMain?.updated_at)
        .format('HH:mm - DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>
.h-detail2 {
  height: calc(100vh - 11rem);
}

.ck-editor__editable {
  min-height: 50px !important;
}

.size-export {
  width: 892px !important;
  margin: auto;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
</style>

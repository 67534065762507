<template>
  <div class="text-black p-3">
    <div class="mt-3">
      <div class="row py-3 fw-500 fs-15 border-b-1">
        <div class="col-md-6 row">
          <div class="col-md-3">Ngày y lệnh</div>
          <div class="col-md-3">Trạng thái</div>
          <div class="col-md-3">Tên xét nghiệm</div>
          <div class="col-md-3 text-center whitespace-nowrap">Ngày chỉ định</div>
        </div>
        <div class="col-md-6 row">

          <div class="col-md-4 text-center">Người chỉ định</div>
          <div class="col-md-4 text-center">Nơi chỉ định</div>
          <div class="col-md-4 text-center">Hành động</div>
        </div>
      </div>
      <!-- // -->
      <div
        v-if="loading"
        class="text-center mt-5"
      >
        <span
          class="spinner-border spinner-border-lg"
          role="status"
          v-if="loading"
        ></span>
      </div>
      <!-- // -->
      <div
        class="row py-3 border-b-1 hover:bg-gray-100"
        v-for="pac in list_pacs"
        :key="pac.id"
        :set="status = getStatus(pac)"
      >
        <div class="col-md-6 row">
          <div class="col-md-3">{{ formatDate(pac.order_at) }}</div>
          <div
            class="col-md-3 txt-pri-corl"
            :class="status && status.class"
          >{{status && status.name}}</div>
          <div class="col-md-3">{{ pac.test_result_name }}</div>
          <div class="col-md-3 text-center">{{ formatDate(pac.indication_at) }}</div>
        </div>
        <div class="col-md-6 row">

          <div class="col-md-4 text-center">
            <div>{{ pac.indication_by_doctor && pac.indication_by_doctor.name }}</div>
            <div>{{ changeType(pac.indication_by_doctor.type) }}</div>
          </div>
          <div class="col-md-4 text-center">{{ pac.indication_locate }}</div>
          <div
            v-if="status.id === 2"
            class="col-md-4 flex justify-between text-primary"
            @click="getDetail(pac.pac && pac.pac.person_test_result_id)"
          >
            <div class="flex justify-center cursor-pointer">
              <div><svg
                  class="mr-1"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.6971 11.9999C15.6971 13.9799 14.0971 15.5799 12.1171 15.5799C10.1371 15.5799 8.53711 13.9799 8.53711 11.9999C8.53711 10.0199 10.1371 8.41992 12.1171 8.41992C14.0971 8.41992 15.6971 10.0199 15.6971 11.9999Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.117 20.2697C15.647 20.2697 18.937 18.1897 21.227 14.5897C22.127 13.1797 22.127 10.8097 21.227 9.39973C18.937 5.79973 15.647 3.71973 12.117 3.71973C8.58703 3.71973 5.29703 5.79973 3.00703 9.39973C2.10703 10.8097 2.10703 13.1797 3.00703 14.5897C5.29703 18.1897 8.58703 20.2697 12.117 20.2697Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="mt-0.5">Xem chi tiết</div>
            </div>
          </div>
          <div
            v-if="!status || status.id !== 2"
            class="col-md-4 flex justify-between text-primary"
          >
            <div
              class=" flex justify-center  cursor-pointer"
              @click="getLinkDownloadAws(pac.pac)"
            >
              <div><svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6309 20H5.63086C5.36564 20 5.11129 20.1054 4.92375 20.2929C4.73622 20.4804 4.63086 20.7348 4.63086 21C4.63086 21.2652 4.73622 21.5196 4.92375 21.7071C5.11129 21.8946 5.36564 22 5.63086 22H19.6309C19.8961 22 20.1504 21.8946 20.338 21.7071C20.5255 21.5196 20.6309 21.2652 20.6309 21C20.6309 20.7348 20.5255 20.4804 20.338 20.2929C20.1504 20.1054 19.8961 20 19.6309 20ZM11.9209 17.71C12.016 17.801 12.1281 17.8724 12.2509 17.92C12.3706 17.9729 12.5 18.0002 12.6309 18.0002C12.7617 18.0002 12.8912 17.9729 13.0109 17.92C13.1336 17.8724 13.2458 17.801 13.3409 17.71L17.3409 13.71C17.5292 13.5217 17.635 13.2663 17.635 13C17.635 12.7337 17.5292 12.4783 17.3409 12.29C17.1526 12.1017 16.8972 11.9959 16.6309 11.9959C16.3646 11.9959 16.1092 12.1017 15.9209 12.29L13.6309 14.59V3C13.6309 2.73478 13.5255 2.48043 13.338 2.29289C13.1504 2.10536 12.8961 2 12.6309 2C12.3656 2 12.1113 2.10536 11.9238 2.29289C11.7362 2.48043 11.6309 2.73478 11.6309 3V14.59L9.34086 12.29C9.24762 12.1968 9.13693 12.1228 9.01511 12.0723C8.89329 12.0219 8.76272 11.9959 8.63086 11.9959C8.499 11.9959 8.36843 12.0219 8.24661 12.0723C8.12479 12.1228 8.0141 12.1968 7.92086 12.29C7.82762 12.3832 7.75366 12.4939 7.7032 12.6158C7.65274 12.7376 7.62677 12.8681 7.62677 13C7.62677 13.1319 7.65274 13.2624 7.7032 13.3842C7.75366 13.5061 7.82762 13.6168 7.92086 13.71L11.9209 17.71Z"
                    fill="currentColor"
                  />
                </svg>

              </div>
              <div class="mt-0.5">Tải</div>
            </div>
            <div class="flex justify-center cursor-pointer">
              <div><svg
                  class="mr-2"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.6971 11.9999C15.6971 13.9799 14.0971 15.5799 12.1171 15.5799C10.1371 15.5799 8.53711 13.9799 8.53711 11.9999C8.53711 10.0199 10.1371 8.41992 12.1171 8.41992C14.0971 8.41992 15.6971 10.0199 15.6971 11.9999Z"
                    stroke="#B1B1B1"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.117 20.2697C15.647 20.2697 18.937 18.1897 21.227 14.5897C22.127 13.1797 22.127 10.8097 21.227 9.39973C18.937 5.79973 15.647 3.71973 12.117 3.71973C8.58703 3.71973 5.29703 5.79973 3.00703 9.39973C2.10703 10.8097 2.10703 13.1797 3.00703 14.5897C5.29703 18.1897 8.58703 20.2697 12.117 20.2697Z"
                    stroke="#B1B1B1"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="mt-0.5 text-muted">Xem chi tiết</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
  <script>
const statuses = [
  { id: 1, name: 'Đang xử lý', class: 'text-warning' },
  { id: 2, name: 'Hoàn thành', class: 'text-success' },
  { id: 3, name: 'Không xử lý được tài liệu', class: 'text-danger' }
]
export default {
  name: 'ChanDoanHinhAnh',
  data () {
    return {
      has_option: 0,
      list_pacs: null,
      statuses,
      test_id: null,
      loading: false
    }
  },
  mounted () {
    this.getMedicalRecordTest()
  },
  methods: {
    async getLinkDownloadAws (pac) {
      if (pac?.file) {
        this.callGetLinkDownloadAws(pac?.file?.file_name)
      } else {
        this.callGetLinkDownloadAws(pac.files)
      }
    },
    async callGetLinkDownloadAws (name) {
      const self = this
      const params = {
        fileName: name
      }
      const header = {
        'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
      }
      await self.$rf.getRequest('AuthRequest').getLinkDownloadAws(params, header, false).then((r) => {
        if (r?.data?.data) window.open(r.data.data)
      }).catch((e) => {
        self.$toast.open({
          message: 'File không tồn tại hoặc gặp lỗi trong quá trình tải xuống',
          type: 'warning'
          // all other options
        })
        console.error(e)
      })
    },
    getStatus (tr) {
      if (tr?.type !== 3) {
        return this.statuses[1]
      } else {
        if (tr.pac) {
          return this.statuses.find(s => s.id === tr.pac.status)
        } else {
          return this.statuses[0]
        }
      }
    },
    changeType (value) {
      if (value === 0) {
        return 'Bác sĩ'
      } else if (value === 1) {
        return 'Nhân viên y tế'
      } else if (value === 2) {
        return 'Bác sĩ chuyên khoa'
      } else if (value === 3) {
        return 'Điều dưỡng'
      } else if (value === 4) {
        return 'Khác'
      }
    },
    async getMedicalRecordTest () {
      let self = this
      self.loading = true
      const params = {
        type: [3]
      }
      try {
        await self.$rf.getRequest('DoctorRequest').getMedicalRecordTest(self.$route?.params?.id, params).then((res) => {
          self.list_pacs = res.data?.data
        })
      } finally {
        self.loading = false
      }
    },
    formatDate (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    getDetail (value) {
      console.log('sadsadsa', value)
      this.getTestByMedicalRecord(value)
    },
    async getTestByMedicalRecord (id) {
      let self = this
      try {
        await self.$rf.getRequest('DoctorRequest').getTestByMedicalRecord(id, self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.tests = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
  </script>
  
  <style scoped>
</style>
<template>
  <el-drawer :visible.sync="isShow" direction="rtl" :size="320">
    <template slot="title">
      <h4
        class="font-bold fs-20 mb-0 text-blue-900"
      >{{ $t('multidisciplinary_board.lbl_revision_history') }}</h4>
    </template>
    <div v-loading="isFetching" class="mt-2">
      <div v-if="emrVersions.length" class="flex flex-col">
        <div
          v-for="version in emrVersions"
          :key="version.id"
          @click="handleShowVersionDetail(version)"
          :set="isActive=handleCheckActive(version)"
        >
          <div
            class="cs-version"
            :class="{
            'cs-active':isActive
          }"
            v-if="version"
          >
            <div
              v-if="checkCurrent(version)"
              class="italic fs-14 text-blue-900"
            >{{ $t('multidisciplinary_board.lbl_current_version') }}</div>

            <div class="flex gap-1">
              <div class="fs-16">{{ $t('multidisciplinary_board.lbl_time') }}:</div>
              <div class="fs-16 font-bold">{{ getDate(version) }}</div>
            </div>
            <div v-if="version.update_by_infor" class="flex gap-1 items-center cursor-pointer">
              <div :set="userInfo=version.update_by_infor" style="max-width: 32px">
                <ImageBox
                  v-if="userInfo.avatar"
                  class="avatar avatar-sm avatar-rounded object-cover object-top border"
                  avatar
                  :source="getImageURL(userInfo.avatar)"
                  :alt="userInfo.name"
                />
                <span
                  v-if="!userInfo||!userInfo.avatar"
                  class="avatar avatar-sm avatar-rounded bg-pri text-white"
                >
                  {{
                  userInfo.name.charAt(0) ||
                  userInfo.email.charAt(0)
                  }}
                </span>
              </div>
              <span class="font-bold">{{version.update_by_infor.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center mt-20 fs-16"
        v-else
      >{{$t('multidisciplinary_board.lbl_no_record_of_version_updates') }}</div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
import ImageBox from '@/components/Common/Image.vue'
import appUtils from '../../utils/appUtils'

export default {
  name: 'EMRVersionList',
  components: { ImageBox },
  data () {
    return {
      isShow: false
    }
  },
  computed: {
    ...mapState({
      emrVersions: state => state.emrStore.emrVersions || [],
      isFetching: state => state.emrStore.isFetching
    })
  },
  created () {
    this.$eventBus.$on('onShowEMRVersion', this.showDrawer)
  },
  destroyed () {
    this.$eventBus.$off('onShowEMRVersion', this.showDrawer)
  },
  methods: {
    showDrawer (show = false) {
      this.isShow = show
    },
    getDate (data) {
      if (!data.update_time) return

      return window.moment(data.update_time).format('HH:mm - DD/MM/YYYY')
    },
    getImageURL (avatar) {
      if (avatar.includes('https')) return avatar
      return appUtils.getImageURL(avatar)
    },
    handleShowVersionDetail (version) {
      if (!version?.id) return

      this.$router.push({
        name: 'EMRVersion',
        params: {
          id: version.id
        }
      })
    },
    handleCheckActive (version) {
      return (
        Number(this.$route.params.id) === version.id &&
        this.$route.name === 'EMRVersion'
      )
    },
    checkCurrent (version) {
      return version.is_current_version === 2
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-version {
  display: flex;
  flex-direction: column;
  padding: 16px;

  gap: 8px;
  border-bottom: 1px solid #d2d2d2;

  &:hover {
    background-color: #e2f3eb;
  }
}

.cs-active {
  background-color: #e2f3eb;
}

::v-deep {
  #el-drawer__title {
    margin-bottom: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #d2d2d2;
  }
}
</style>

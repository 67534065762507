<template>
  <div
    ref="ModalCreateGiayChuyenTuyen"
    class="modal fade"
    id="ModalCreateGiayChuyenTuyen"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalCreateGiayChuyenTuyenLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between p-3 mt-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span>GIẤY CHUYỂN TUYẾN</span>
          </div>
          <button
            @click="closeModal"
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Họ và tên:</label>
                <label for>{{ person.name }}</label>
              </div>
            </div>
            <div class="col-md-3 flex h-10">
              <label class="fs-14 fw-700 mr-1">Giới tính:</label>
              <label for>{{ person && person.gender === 1 ? "Nam" : "Nữ" }}</label>
            </div>
            <div class="col-md-3 flex h-10">
              <div class>
                <label class="fs-14 fw-700 mr-1">Ngày sinh:</label>
                <label for>
                  {{
                  person.birthday &&
                  moment(person.birthday).format("DD/MM/YYYY")
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Địa chỉ:</label>
                <label for>{{ person.address }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Quốc tịch:</label>
                <label for>{{ person.nationality_name || ''}}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Dân tộc:</label>
              <label for>{{ person.ethnic_name || ''}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Nghề nghiệp:</label>
                <label for>{{ person.career || ''}}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Nơi làm việc:</label>
              <label for>{{ person.work_at || ''}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Số thẻ bảo hiểm y tế:</label>
                <label for>{{ person.insurance_number || "" }}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Hạn sử dụng:</label>
                <label for>
                  {{
                  person.insurance_expire &&
                  moment(person.insurance_expire).format("DD/MM/YYYY")
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Kết quả xét nghiệm, cận lâm sàng:</label>
                <label for v-html="medicalRecordMain && medicalRecordMain.laboratory_test_summary"></label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 flex h-10">
              <div class>
                <label class="fs-14 fw-700">Chẩn đoán:</label>
                <label class="ml-2">
                  {{
                  medicalRecordMain && medicalRecordMain.treat_dept_main_name
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700">Bệnh viện muốn chuyển đến:</label>
              <b-form-input v-model="form.transit_hospital_name" placeholder="Tên bệnh viện..."></b-form-input>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700">Số chuyển tuyến:</label>
              <b-form-input v-model="form.transit_code" placeholder="Nơi khám bệnh..."></b-form-input>
            </div>
          </div>
          <div class="fw-700 mt-3">Nơi khám bệnh, điều trị</div>
          <div v-for="(item, index) in form.transit_ticket_transactions" :key="index">
            <div class="row mb-2">
              <div class="col-md-5 flex">
                <div class="w-100">
                  <label class="fs-14 w-44">Nơi khám bệnh:</label>
                  <b-form-input v-model="item.transit_hospital_name" placeholder="Nơi khám bệnh..."></b-form-input>
                </div>
              </div>
              <div class="col-md-2 flex">
                <div class="w-100">
                  <label class="fs-14 mr-1">Tuyến:</label>
                  <b-form-input v-model="item.hospital_tier" placeholder="Tuyến..."></b-form-input>
                </div>
              </div>
              <div class="col-md-2 flex">
                <div class="w-100">
                  <label class="fs-14 mr-1">Ngày vào:</label>
                  <input class="form--input" type="date" v-model="item.start_time" />
                </div>
              </div>
              <div class="col-md-2 flex">
                <div class="w-100">
                  <label class="fs-14 mr-1">Ngày ra:</label>
                  <input class="form--input" type="date" v-model="item.end_time" />
                </div>
              </div>
              <div class="flex items-center justify-center col-md-1" v-if="index !== 0">
                <img src="/images/recycle-bin.svg" alt class="icon" @click="deleteLocation(index)" />
              </div>
            </div>
          </div>
          <div class="add-location" @click="handleAddLocation">+ Thêm nơi khám bệnh, điều trị</div>
          <h2 class="uppercase font-semibold mt-4">Tóm tắt bệnh án</h2>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="fs-14 fw-700">Dấu hiệu lâm sàng:</label>
                <b-form-textarea
                  v-model="form.clinical_signs"
                  placeholder="Dấu hiệu lâm sàng..."
                  rows="3"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label
                  class="fs-14 fw-700"
                >Phương pháp, thủ thuật, kỹ thuật, thuốc đã sử dụng trong điều trị:</label>
                <b-form-textarea
                  v-model="form.trick_method"
                  placeholder="Phương pháp, thủ thuật, kỹ thuật, thuốc..."
                  rows="3"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="fs-14 fw-700">Tình trạng người bệnh lúc chuyển tuyến:</label>
                <b-form-textarea
                  v-model="form.patient_transit_status"
                  placeholder="Tình trạng..."
                  rows="3"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="fs-16 fw-700 mb-2">Lý do chuyển tuyến</div>
          <div class="mb-2">
            <el-radio-group v-model="form.transit_reason">
              <el-radio :label="1">Đủ điều kiện chuyển tuyến</el-radio>
              <el-radio
                :label="2"
              >Theo yêu cầu của người bệnh hoặc người đại diện hợp pháp của người bệnh</el-radio>
            </el-radio-group>
          </div>
          <div class="row" v-if="form.transit_reason === 1">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="fs-14 fw-700">Điều kiện chuyển tuyến:</label>
                <el-checkbox-group v-model="transit_condition" class="mt-2">
                  <el-checkbox :label="1">Phù hợp với quy định chuyển tuyến</el-checkbox>
                  <el-checkbox
                    :label="2"
                  >Không phù hợp với khả năng đáp ứng của cơ sở khám bệnh, chữa bệnh</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>

          <div class="row" v-if="form.transit_reason === 2">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="fs-14 fw-700">Hướng điều trị:</label>
                <b-form-textarea
                  v-model="form.treatment_direction"
                  placeholder="Hướng điều trị..."
                  rows="3"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="row" v-if="form.transit_reason === 2">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="fs-14 fw-700">Chuyển tuyến vào:</label>
                <input class="form--input" type="datetime-local" v-model="form.transit_time" />
              </div>
            </div>
          </div>
          <div class="row" v-if="form.transit_reason === 2">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="fs-14 fw-700">Phương tiện vận chuyển:</label>
                <b-form-textarea
                  v-model="form.transit_transport"
                  placeholder="Phương tiện vận chuyển..."
                  rows="3"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="row" v-if="form.transit_reason === 2">
            <div class="col-md-12">
              <div class="mb-3">
                <label
                  class="fs-14 fw-700"
                >Họ tên, chức danh, trình độ chuyên môn của người hộ tống (nếu có):</label>
                <b-form-textarea
                  v-model="form.escort"
                  placeholder="Họ tên, chức danh, trình độ chuyên môn của người hộ tống (nếu có)..."
                  rows="3"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-700">Bác sĩ, y sỹ khám, điều trị:</label>
                  <el-select
                    v-model="form.examiner_id"
                    placeholder="Chọn Bác sĩ, y sỹ khám, điều trị"
                    class="w-100"
                    clearable
                  >
                    <el-option
                      v-for="item in listDoctors"
                      :key="item.doctor.id"
                      :label="item.doctor.user.name"
                      :value="item.doctor.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-700">Người có thẩm quyền chuyển tuyến:</label>
                  <el-select
                    v-model="form.referral_authority_id"
                    placeholder="Chọn Người có thẩm quyền chuyển tuyến"
                    class="w-100"
                    clearable
                  >
                    <el-option
                      v-for="item in listDoctors"
                      :key="item.doctor.id"
                      :label="item.doctor.user.name"
                      :value="item.doctor.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <el-button @click="onSubmit" type="primary" class="mt-3" :loading="isLoading">Lưu phiếu</el-button>
        </div>
      </div>
    </div>
    <div v-if="showPrint">
      <div v-show="false" ref="documentPrint">
        <ContentFormGiayChuyenTuyen
          :detail="form"
          :listDoctors="listDoctors"
          :detailForm="form"
          :person="person"
          :medicalRecordMain="medicalRecordMain"
        ></ContentFormGiayChuyenTuyen>
      </div>
    </div>
  </div>
</template>
  <script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
// import moment from 'moment'
import { ENVELOPE_DOCUMENT_TYPE, SIGNATORY_ROLE } from '@/utils/constants'
import { mapState } from 'vuex'
import appUtils from '@/utils/appUtils'
import ContentFormGiayChuyenTuyen from './ContentFormGiayChuyenTuyen.vue'
import { mixinSignPdf } from '../../../../utils/mixinSignPdf'
// import { USE_ROOM_PRICE_CR } from '../../../../utils/constants'
export default {
  name: 'ModalCreateGiayChuyenTuyen',
  components: { CkeditorNormal, ContentFormGiayChuyenTuyen },
  mixins: [mixinSignPdf],
  props: ['type', 'listDoctors'],
  data () {
    const defaultForm = {
      transit_code: '', // Số chuyển tuyến
      transit_hospital_name: '', // Tên bệnh viện muốn chuyển đến
      clinical_signs: '', // Dấu hiệu lâm sàng
      trick_method: '', // Phương pháp, thủ thuật, kỹ thuật
      patient_transit_status: '', // tình trạng bệnh nhân chuyển tuyến
      transit_condition: 0, // Điều kiện chuyển tuyến
      transit_reason: 0, // Lý do chuyển tuyến
      treatment_direction: '', // Hướng điều trị
      transit_time: 0, // Thời gian chuyển tuyến
      transit_transport: '', // Phương tiện chuyển tuyến
      escort: '', // Người hộ tống
      examiner_id: '', // Bác sĩ khám
      referral_authority: {},
      examiner: {},
      referral_authority_id: '', // Người có thẩm quyền chuyển tuyến
      medical_record_id: 0, // ID bệnh án
      transit_ticket_transactions: [
        // Nơi điều trị
        {
          transit_hospital_name: this.$globalClinic?.organization.name, // Tên bệnh viện
          hospital_tier: '', // Tên tuyến
          start_time:
            new Date(
              this.medicalRecordMain && this.medicalRecordMain.reception_at
            ).getTime() || 0, // Thời gian bắt đầu
          end_time:
            new Date(
              this.medicalRecordMain && this.medicalRecordMain.discharge_at
            ).getTime() || 0 // Thời gian kết thúc
        }
      ]
    }

    return {
      form: {
        ...defaultForm
      },
      defaultForm,
      isLoading: false,
      transit_condition: [],
      showPrint: false,
      appUtils
    }
  },
  watch: {
    //
  },
  mounted () {
    if (this.type === 'update') this.getDetailForm()
  },
  computed: {
    ...mapState({
      emrData: state => state.emrData,
      person: state => {
        let newAddress = ''
        const data = state.person
        if (data?.address) {
          newAddress += data.address
        }

        if (data?.address_detail) {
          newAddress += data.address_detail
        }

        if (data?.address_wards_name) {
          newAddress = newAddress + ', ' + data.address_wards_name
        }
        if (data?.address_district_name) {
          newAddress = newAddress + ', ' + data.address_district_name
        }
        if (data?.address_province_name) {
          newAddress = newAddress + ', ' + data.address_province_name
        }

        return {
          ...state.person,
          address: newAddress
        }
      }
    }),
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain || {}
    })
  },
  methods: {
    closeModal () {
      if (this.type === 'create') {
        this.form = { ...this.defaultForm }
        this.form.transit_reason = 0
      }
    },
    openModal (show) {
      window.$('#ModalCreateGiayChuyenTuyen').modal(show ? 'show' : 'hide')
      let self = this
      self.$nextTick(function () {
        if (this.type !== 'update') this.form = { ...this.defaultForm }
        if (this.type === 'update') this.getDetailForm()
        window
          .$('#ModalCreateGiayChuyenTuyen')
          .on('shown.bs.modal', function (e) {})

        window
          .$('#ModalCreateGiayChuyenTuyen')
          .on('hidden.bs.modal', function (e) {
            console.log('Close modal')
          })
      })
    },
    handleAddLocation () {
      this.form.transit_ticket_transactions.push({
        transit_hospital_name: '', // Tên bệnh viện
        hospital_tier: '', // Tên tuyến
        start_time: '',
        end_time: ''
      })
    },
    deleteLocation (index) {
      this.form.transit_ticket_transactions.splice(index, 1)
    },
    async getDetailForm (id) {
      try {
        this.isLoading = true
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailTransitTickets(this.$route?.query?.vId || id)
        this.form = res?.data || {}
        if (this.form.transit_condition === 3) {
          this.transit_condition = []
          this.transit_condition.push(1, 2)
        } else {
          this.transit_condition = []
          this.transit_condition.push(this.form.transit_condition)
        }
        this.form.transit_ticket_transactions = this.form.transit_ticket_transactions.map(
          elm => {
            return {
              ...elm,
              start_time: window.moment(elm.start_time).format('YYYY-MM-DD'),
              end_time: window.moment(elm.end_time).format('YYYY-MM-DD')
            }
          }
        )
        if (this.form.transit_time) {
          this.form.transit_time = window
            .moment(this.form.transit_time)
            .format('YYYY-MM-DDTHH:mm')
        }
        if (!this.form.examiner_id) this.form.examiner_id = ''
        if (!this.form.referral_authority_id) {
          this.form.referral_authority_id = ''
        }
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    },
    async onSubmit () {
      try {
        this.isLoading = true
        let params = {}
        if (this.form.transit_code) params.transit_code = this.form.transit_code
        if (this.form.transit_reason === 2 && this.form.escort) {
          params.escort = this.form.escort
        }
        if (this.form.transit_hospital_name) {
          params.transit_hospital_name = this.form.transit_hospital_name
        }
        if (this.form.clinical_signs) {
          params.clinical_signs = this.form.clinical_signs
        }
        if (this.form.trick_method) params.trick_method = this.form.trick_method
        if (this.form.patient_transit_status) {
          params.patient_transit_status = this.form.patient_transit_status
        }
        if (this.form.transit_reason === 1 && this.transit_condition) {
          params.transit_condition =
            this.transit_condition.length > 1 ? 3 : this.transit_condition[0]
        }
        if (this.form.transit_reason) {
          params.transit_reason = this.form.transit_reason
        }
        if (this.form.transit_reason === 2 && this.form.treatment_direction) {
          params.treatment_direction = this.form.treatment_direction
        }
        if (this.form.transit_reason === 2 && this.form.transit_time) {
          params.transit_time = new Date(this.form.transit_time).getTime()
        }
        if (this.form.transit_reason === 2 && this.form.transit_transport) {
          params.transit_transport = this.form.transit_transport
        }
        if (this.form.examiner_id) params.examiner_id = this.form.examiner_id
        if (this.form.referral_authority_id) {
          params.referral_authority_id = this.form.referral_authority_id
        }
        if (this.form.transit_ticket_transactions.length) {
          params.transit_ticket_transactions = this.form.transit_ticket_transactions?.map(
            elm => {
              return {
                ...elm,
                start_time: new Date(elm.start_time).getTime(),
                end_time: new Date(elm.end_time).getTime()
              }
            }
          )
        }
        params.medical_record_id = +this.$route.params?.id || 0
        let res = null
        if (this.type === 'update') {
          res = await this.$rf
            .getRequest('DoctorRequest')
            .updateTransitTickets(+this.$route.query?.vId, params)
          // if (res) this.$emit('update')
        } else {
          res = await this.$rf
            .getRequest('DoctorRequest')
            .createTransitTickets(params)
          // if (res) {
          //   this.$emit('create')
          //   this.form = { ...this.defaultForm }
          // }
        }
        if (res) {
          await this.getDetailForm(res?.data?.id)
          this.showPrint = true

          this.$nextTick(async () => {
            this.isLoading = true
            const url = await this.onPrintNe()
            await this.handleProcessSignEnvelope(url)

            this.$emit('success')
            this.resetForm()
            this.isLoading = false

            if (this.type === 'update') {
              this.$toast.open({
                message: 'Cập nhật Giấy chuyển tuyến thành công',
                type: 'success'
              })
              this.$emit('update')
            } else {
              this.$toast.open({
                message: 'Tạo mới Giấy chuyển tuyến thành công',
                type: 'success'
              })
              this.$emit('create')
              this.form = { ...this.defaultForm }
            }
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleProcessSignEnvelope (documentUrl) {
      if (!documentUrl) return

      let responseData
      const procedureSelected = await this.getSignProcedures(
        ENVELOPE_DOCUMENT_TYPE.TRANSIT_FORM
      )
      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map(item => {
          const user = this.getUserByRole(item?.user_role)

          return {
            ...item,
            user,
            user_id: user?.id,
            node_signatory_id: item?.id,
            signatory_name: user?.name,
            signatory_email: user?.email,
            signatory_role: SIGNATORY_ROLE.signer,
            signatory_order: item?.sign_order,
            role_name: item?.role_name,
            is_required: item?.is_required,
            expired_date: item?.expired_date || undefined
          }
        })

        return arr.concat(signs)
      }, [])

      const signEnvelope = await this.handleGetEnvelopeByDocumentID(
        this.form.id,
        ENVELOPE_DOCUMENT_TYPE.TRANSIT_FORM
      )

      if (signEnvelope?.id) {
        responseData = await this.handleUpdateSignEnvelope(
          signEnvelope.id,
          signatories,
          documentUrl,
          ENVELOPE_DOCUMENT_TYPE.TRANSIT_FORM,
          this.form.id,
          this.envelopeName
        )

        return responseData
      } else {
        responseData = await this.handleCreateEnvelope(
          signatories,
          documentUrl,
          procedureSelected?.id,
          ENVELOPE_DOCUMENT_TYPE.TRANSIT_FORM,
          this.form.id,
          this.envelopeName
        )

        return responseData
      }
    },
    async onPrintNe () {
      const self = this
      const appendStyle = true
      const bodyHTML = self.$refs.documentPrint.innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML, appendStyle })
      const base64Data = appUtils.base64.decode(tempHTML)
      const url = await this.savePdfToS3(base64Data, false)
      return url
    },
    async savePdfToS3 (base64, isOpen = true) {
      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName: 'GiayChuyenTuyen',
          path: 'consultations',
          type: 'test',
          type_id: 'test',
          uuid: 'test'
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data && isOpen) {
          this.loading = false
          window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
        return res.data?.documentLink
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    getUserByRole (role) {
      let user
      switch (role) {
        case 'examiner':
          const examiner = this.form?.examiner_id
          if (!examiner) {
            // eslint-disable-next-line standard/no-callback-literal
            this.loading = false
          } else {
            user = this.form?.examiner?.User || {}
          }

          break
        case 'referral_authority':
          const referral_authority = this.form?.referral_authority_id
          if (!referral_authority) {
            // eslint-disable-next-line standard/no-callback-literal
            this.loading = false
          } else {
            user = this.form?.referral_authority?.User || {}
          }

          break

        default:
          break
      }
      return user
    },
    resetForm () {
      this.form = { ...this.defaultForm }
      this.isLoading = false
    }
  }
}
</script>
  <style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: '\02026';
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
.modal-body {
  max-height: 700px;
  overflow: auto;
  font-weight: 400;
}
.form--input {
  line-height: 44px;
}
.add-location {
  color: #20419b;
  display: flex;
  justify-content: end;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.modal-dialog {
  @media screen and (min-width: 1200px) {
    width: 80%;
    max-width: unset;
  }
}
.icon {
  width: 40px;
  object-fit: contain;
  transition: all ease-in-out 0.2s;
  &:hover {
    scale: 1.1;
  }
}
</style>
  
<template>
  <div
    id="phieuChuyenTuyen"
    class="mx-auto overflow-hidden hodo-print"
    :style="`width: ${customWidth} !important`"
  >
    <div class="px-8 mt-2 font-normal">
      <div class="block fs-14">
        <div class="col-3">
          <div class="flex" style="max-width: 250px">
            <div class="text-center">
              <div>
                <div class="mb-0 uppercase font-bold fs-17">
                  {{
                  $globalClinic && $globalClinic.organization
                  ? $globalClinic.organization.authority_in_charge
                  : ""
                  }}
                </div>
              </div>
              <div class>
                <div class="mb-0 uppercase font-bold fs-17">
                  {{
                  $globalClinic && $globalClinic.organization
                  ? $globalClinic.organization.name
                  : ""
                  }}
                </div>
              </div>
              <div class>
                <div class="mb-0 fs-17">------</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 font-bold mb-6">
          <div class="flex flex-col justify-end">
            <div class="flex justify-center">
              <div class="font fs-17">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
            </div>
            <div class="flex justify-center">
              <div class="font fs-17">Độc lập - Tự do - Hạnh phúc</div>
            </div>
            <div class="flex justify-center">
              <label class="font fs-17">---------------</label>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="flex">
            <div class="text-left">
              <div class="text-left ml-2">
                <div class="mb-0 fs-17">Số hồ sơ: ......................</div>
              </div>
              <div class="text-left ml-2">
                <div
                  class="mb-0 fs-17"
                >Vào sổ chuyển tuyến số: {{ detailForm && detailForm.transit_code || '......................' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div
          class="font font-bold flex flex-col justify-center items-center uppercase fs-24"
        >Giấy chuyển tuyến khám bệnh, chữa bệnh bảo hiểm y tế</div>
        <div class="font text-center fs-18 mt-1">
          Kính gửi:
          <span
            class="font-semibold"
          >{{ detailForm && detailForm.transit_hospital_name || '....................................................................................'}}</span>
        </div>
      </div>
      <div class="block mt-3 mb-2">
        <div class="w-full inline-block">
          <div
            class="font fs-18 mb-2"
          >Cơ sở khám bệnh, chữa bệnh: {{$globalClinic.organization.name}}. Trân trọng giới thiệu:</div>
          <div class="flex justify-between" style="max-width: 98%">
            <div>
              <label class="mr-1 font fs-18">Họ tên người bệnh:</label>
              <span class="uppercase font font-bold fs-18">
                {{
                (info && info.person && info.person.name) || person && person.name || '...........................................'
                }}
              </span>
            </div>
            <div class="mr-6">
              <label class="ml-6 font fs-18">Giới tính:</label>
              <span class="font fs-18">
                {{(info && info.person && info.person.gender === 1) ||
                (person.gender && person.gender === 1)
                ? "Nam"
                : (info && info.person && info.person.gender === 2) ||
                (person.gender && person.gender === 2)
                ? 'Nữ' : '..............'
                }}
              </span>
            </div>
            <div class="flex">
              <div class="font fs-18">Năm sinh:</div>
              <div class="mb-0 ml-1 font fs-18">
                {{ getYear(info && info.person && info.person.birthday) ||
                getYear( person && person.birthday) || '..........'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex">
              <div class="font fs-18">Địa chỉ:</div>
              <div
                class="mb-0 ml-1 font fs-18"
              >{{getAddress(info && info.person) || person && getAddress(person) || '.................................................................................................................................'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="flex justify-between">
            <div class="flex-1">
              <label class="mr-1 font fs-18">Quốc tịch:</label>
              <span class="font fs-18">
                {{
                (info && info.person && info.person.nationality_name) || person && person.nationality_name || '...........................................'
                }}
              </span>
            </div>
            <div class="mr-6 flex-1">
              <label class="ml-6 font fs-18">Dân tộc:</label>
              <span class="font fs-18">
                {{
                (info && info.person && info.person.ethnic_name) || person && person.ethnic_name || '...........................................'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="flex justify-between">
            <div class="flex-1">
              <label class="mr-1 font fs-18">Nghề nghiệp:</label>
              <span class="font fs-18">
                {{
                (info && info.person && info.person.career) || person && person.career || '...........................................'
                }}
              </span>
            </div>
            <div class="mr-6 flex-1">
              <label class="ml-6 font fs-18">Nơi làm việc:</label>
              <span class="font fs-18">
                {{
                (info && info.person && info.person.work_at) || person && person.work_at || '...........................................'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex">
              <div class="mb-0 font fs-18">Số thẻ bảo hiểm y tế:</div>
            </div>
          </div>
        </div>
      </div>

      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex fs-18">
              <BoxNumber
                style="width: 60% !important"
                bhyt="true"
                :str="info && info.person && info.person.insurance_number || person &&  person.insurance_number"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex">
              <div class="font fs-18">Thời hạn sử dụng thẻ BHYT đến ngày:</div>
              <!-- <div class="mb-0 ml-1 font fs-18">Từ</div>
                  <div
                    v-if="person.insurance_start_date"
                    class="mb-0 ml-1 font fs-18"
                  >{{ formatDate(person.insurance_start_date) }}</div>
                  <div v-else class="mb-0 ml-1 fs-18">....../....../......</div>
              <div class="mb-0 ml-4 font fs-18">Đến</div>-->
              <div
                v-if=" person.insurance_expire"
                class="mb-0 ml-1 font fs-18"
              >{{ formatDate( person && person.insurance_expire) }}</div>
              <div v-else class="mb-0 ml-1 fs-18">........../........../..........</div>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex items-center">
              <div class="mb-0 font fs-18">Hết hạn:</div>
              <div class="h-3 w-3 box-nv mx-0 mr-4 mt-0 ml-2 flex justify-center items-center">
                <span v-if="false" class="fs-11 m-0 p-0 font-bold">X</span>
              </div>
              <div class="mb-0 font fs-18">Không xác định thời hạn:</div>
              <div class="h-3 w-3 box-nv mx-0 mr-1 mt-0 ml-2 flex justify-center items-center">
                <span v-if="false" class="fs-11 m-0 p-0 font-bold">X</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div style="max-width: 98%">
            <div class="font fs-18">Đã được khám bệnh, điều trị:</div>
            <div
              class="ml-2"
              v-for="(item, index) in detailForm.transit_ticket_transactions"
              :key="index"
            >
              <span class="font fs-18">- Tại:</span>
              <span class="mb-0 ml-1 font fs-18">
                {{
                item.transit_hospital_name || ''
                }}
              </span>
              <span
                class="font fs-18 ml-1"
              >(Tuyến {{ item.hospital_tier || '...............................' }})</span>
              <span
                class="font fs-18 ml-1"
                v-if="item.start_time"
              >từ ngày {{ formatStringDate(item.start_time) }}</span>
              <span
                v-else
                class="mb-0 ml-1 fs-18"
              >từ ngày .......... tháng .......... năm ..........</span>
              <span
                class="font fs-18 ml-1"
                v-if="item.end_time"
              >đến ngày {{ formatStringDate(item.end_time) }}</span>
              <span
                v-else
                class="mb-0 ml-1 fs-18"
              >đến ngày .......... tháng .......... năm ..........</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex">
              <div class="font font-bold uppercase fs-20">Tóm tắt bệnh án</div>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="overflow-hidden">
            <div
              class="whitespace-nowrap float-left mr-1 font fs-18 fontForAll"
            >- Dấu hiệu lâm sàng:</div>
            <div
              class="mb-0 font fs-18 fontForAll ml-2"
            >{{ detailForm.clinical_signs || '.......................................................................................................................................................' }}</div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="overflow-hidden">
            <div
              class="whitespace-nowrap float-left mr-1 font fs-18 fontForAll"
            >- Kết quả xét nghiệm, cận lâm sàng:</div>
            <div
              v-if="medicalRecordMain && medicalRecordMain.laboratory_test_summary"
              class="mb-0 font fs-18 fontForAll ml-2"
              v-html="medicalRecordMain.laboratory_test_summary"
            ></div>
            <div
              v-else
              class="mb-0 font fs-18 fontForAll mt-1"
            >.........................................................................................................................................</div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="overflow-hidden">
            <div class="whitespace-nowrap float-left mr-1 font fs-18 fontForAll">- Chẩn đoán:</div>
            <div
              v-if=" (info && info.icd_name) ||
                    (medicalRecordMain &&
                      medicalRecordMain.treat_dept_main_name)
                   
                  "
              class="mb-0 font fs-18 fontForAll ml-2"
            >
              {{ (info && info.icd_name) ||
              medicalRecordMain.treat_dept_main_name
              }}
            </div>
            <div
              class="mt-1"
              v-else
            >.................................................................................................................................................................................</div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="overflow-hidden flex">
            <div
              class="whitespace-nowrap float-left mr-1 font fs-18"
            >- Phương pháp, thủ thuật, kỹ thuật, thuốc đã sử dụng trong điều trị:</div>
          </div>

          <div>
            <div
              v-if="detailForm && detailForm.trick_method"
              class="mb-0 font fs-18 fontForAll ml-2"
            >{{ detailForm.trick_method }}</div>
            <div
              v-else
              class="mb-0 font fs-18 fontForAll mt-1"
            >.....................................................................................................................................................................................................</div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="overflow-hidden flex">
            <div
              class="whitespace-nowrap float-left mr-1 font fs-18"
            >- Tình trạng người bệnh lúc chuyển tuyến:</div>
          </div>
          <div
            v-if="detailForm && detailForm.patient_transit_status"
            class="mb-0 font fs-18 fontForAll ml-2"
          >{{ detailForm.patient_transit_status }}</div>
          <div
            v-else
            class="mt-1"
          >.....................................................................................................................................................................................................</div>
        </div>
      </div>
      <!-- <div class="block mb-2">
            <div class="w-full inline-block">
              <div class="overflow-hidden">
                <div class="whitespace-nowrap float-left mr-1 font fs-18">Lời dặn:</div>
                <div v-if="noteFormat" class="mb-0 font fs-18">{{ noteFormat }}</div>
              </div>
            </div>
      </div>-->
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="overflow-hidden">
            <div class="break-words float-left mr-1 font fs-18" style="max-width: 98%">
              - Lý do chuyển tuyến: Khoanh tròn vào mục 1 hoặc 2 lý do chuyển tuyến.
              Trường hợp chọn mục 1, đánh dấu (X) vào ô tương ứng.
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2 ml-2">
        <div class="w-full inline-block">
          <div class="overflow-hidden">
            <div
              class="whitespace-nowrap float-left mr-1 font fs-18"
              :class="detailForm.transit_reason === 1 ? 'font-semibold italic' : ''"
            >1. Đủ điều kiện chuyển tuyến:</div>
          </div>
        </div>
        <div class="w-full inline-block">
          <div class="overflow-hidden">
            <div
              class="whitespace-nowrap float-left ml-2 font fs-18 mr-2"
            >a. Phù hợp với quy định chuyển tuyến</div>
            <div class="h-3 w-3 box-nv mx-0 mr-1 mt-2 ml-2 flex justify-center items-center">
              <span
                v-if="detailForm.transit_reason === 1 && (detailForm.transit_condition === 1 || detailForm.transit_condition === 3)"
                class="fs-11 m-0 p-0 font-bold"
              >X</span>
            </div>
          </div>
          <div class="overflow-hidden mt-1">
            <div
              class="whitespace-nowrap float-left ml-2 font fs-18 mr-2"
            >b. Không phù hợp với khả năng đáp ứng của cơ sở khám bệnh, chữa bệnh.</div>
            <div class="h-3 w-3 box-nv mx-0 mr-1 ml-2 mt-2 flex justify-center items-center">
              <span
                v-if="detailForm.transit_reason === 1 && (detailForm.transit_condition === 2 || detailForm.transit_condition === 3)"
                class="fs-11 m-0 p-0 font-bold"
              >X</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="overflow-hidden">
            <div
              class="whitespace-nowrap float-left mr-1 font fs-18"
              :class="detailForm.transit_reason === 2 ? 'font-semibold italic' : ''"
            >2. Theo yêu cầu của người bệnh hoặc đại diện hợp pháp của người bệnh.</div>
          </div>
        </div>
        <div class="w-full inline-block">
          <div class="overflow-hidden">
            <span class="whitespace-nowrap float-left ml-2 font fs-18 title">- Hướng điều trị:</span>
            <div
              v-if="detailForm.transit_reason === 2 && detailForm && detailForm.treatment_direction"
              class="mb-0 font fs-18 fontForAll"
            >
              <span class="ml-2">{{ detailForm.treatment_direction }}</span>
            </div>
            <div
              v-else
              class="whitespace-nowrap float-left ml-2 font fs-18"
            >.................................................................................................</div>
          </div>
          <div class="overflow-hidden">
            <span class="whitespace-nowrap float-left ml-2 font fs-18 title">- Chuyển tuyến hồi:</span>
            <div
              v-if="detailForm.transit_reason === 2 && detailForm && detailForm.transit_time"
              class="mb-0 font fs-18 fontForAll ml-2"
            >
              <span
                class="ml-2"
              >{{ formatStringTime(detailForm.transit_time) + ', ' + formatStringDate(detailForm.transit_time) }}</span>
            </div>
            <div
              v-else
              class="whitespace-nowrap float-left ml-2 font fs-18"
            >.......... giờ .......... phút, ngày .......... tháng .......... năm ..........</div>
          </div>
          <div class="overflow-hidden">
            <span
              class="whitespace-nowrap float-left ml-2 font fs-18 title"
            >- Phương tiện vận chuyển:</span>
            <div
              v-if="detailForm.transit_reason === 2 && detailForm && detailForm.transit_transport"
              class="mb-0 font fs-18 fontForAll ml-2"
            >
              <span class="ml-2">{{ detailForm.transit_transport }}</span>
            </div>
            <div
              v-else
              class="whitespace-nowrap float-left ml-2 font fs-18"
            >.........................................................................................</div>
          </div>
          <div class="overflow-hidden">
            <span
              class="whitespace-nowrap float-left ml-2 font fs-18 title"
            >- Họ tên, chức danh, trình độ chuyên môn của người hộ tống (nếu có):</span>
            <div
              v-if="detailForm.transit_reason === 2 && detailForm && detailForm.escort"
              class="mb-0 font fs-18 fontForAll ml-2"
            >
              <span class="ml-2">{{ detailForm.escort }}</span>
            </div>
            <div
              v-else
              class="whitespace-nowrap float-left ml-2 font fs-18"
            >..............................................</div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="col-6 text-center"></div>
        <div class="col-6 text-center">
          <div v-if=" (info && info.start_time)" class="mb-0 ml-1 font fs-18">
            {{getHMDMYVN(info && info.start_time)
            }}
          </div>
          <div v-else class="font fs-17">.........., ngày .......... tháng .......... năm ..........</div>
        </div>
      </div>
      <div class="block">
        <div class="col-6 text-center">
          <div class="font-bold uppercase font fs-17">Bác sĩ, y sĩ khám, điều trị</div>
          <div class="text-italic font fs-17" style="height: 24px"></div>
          <div class="fs-17 font-bold mt-20 font fs-17">{{ examiner }}</div>
        </div>
        <div class="col-6 text-center">
          <div class="font-bold uppercase font fs-17">Người có thẩm quyền chuyển tuyến</div>
          <div class="text-italic font fs-17">(Ký tên, đóng dấu)</div>
          <div class="fs-17 font-bold mt-20 font fs-17">{{ referral_authority_id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import BoxNumber from '../../../BoxNumber.vue'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
export default {
  name: 'ContentFormGiayChuyenTuyen',
  mixins: [mixinGetPersonInfo],
  components: { BoxNumber },
  props: [
    'customWidth',
    'info',
    'person',
    'detailForm',
    'medicalRecordMain',
    'listDoctors'
  ],
  data () {
    return {
      //
    }
  },
  computed: {
    examiner () {
      return this.listDoctors?.find(
        elm => elm.doctor.id === this.detailForm.examiner_id
      )?.doctor?.user?.name
    },
    referral_authority_id () {
      return this.listDoctors?.find(
        elm => elm.doctor.id === this.detailForm.referral_authority_id
      )?.doctor?.user?.name
    }
  },
  methods: {
    formatStringDate (date) {
      const dateTime = moment(date)
      let _date = dateTime.date()
      if (_date.toString().length === 1) _date = `0${_date}`
      let month = dateTime.month() + 1
      if (month.toString().length === 1) month = `0${month}`

      return ` ngày ${_date} tháng ${month} năm ${dateTime.year()}`
    },
    formatStringTime (date) {
      const dateTime = moment(date)
      let stringMinute = ''
      let stringHour = ''
      if (!isNaN(dateTime.minute())) {
        if (dateTime.minute() === 0) {
          stringMinute = '00'
        } else {
          stringMinute = dateTime.minute()
          if (stringMinute.toString().length === 1) {
            stringMinute = `0${stringMinute}`
          }
        }
      } else {
        stringMinute = '......'
      }
      if (!isNaN(dateTime.hour())) {
        if (dateTime.hour() === 0) {
          stringHour = '00'
        } else {
          stringHour = dateTime.hour()
          if (stringHour.toString().length === 1) stringHour = `0${stringHour}`
        }
      } else {
        stringHour = '......'
      }
      return `${stringHour} giờ ${stringMinute} phút`
    },
    formatTime (date) {
      return window.moment(date).format('HH:mm')
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatDateUTC (date) {
      const start_time = new Date(date)
      const dd = start_time
        .getUTCDate()
        .toString()
        .padStart(2, '0') // Get day in UTC format
      const mm = (start_time.getUTCMonth() + 1).toString().padStart(2, '0') // Get month in UTC format (Note: Months are 0-based)
      const yyyy = start_time.getUTCFullYear() // Get year in UTC format

      const formattedDate = `${dd}/${mm}/${yyyy}`
      return formattedDate
    },
    getDate (date) {
      return window.moment(date).format('YYYY-MM-DD')
    },
    getHMDMYVN (date) {
      const string = this.moment(date).locale(this.$lang)
      if (this.$lang === 'vi') {
        // let hm = dt.clone().format('HH:mm')
        const dddd = string.clone().format('dddd')
        const d = string.clone().format('DD')
        const m = string.clone().format('MM')
        const y = string.clone().format('YYYY')

        const arr = dddd.split(' ')
        // loop through each element of the array and capitalize the first letter.
        for (var i = 0; i < arr?.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }
        // Join all the elements of the array back into a string
        // using a blankspace as a separator
        const ddddCapitalized = arr.join(' ')
        return ddddCapitalized + ', ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    getYear (date) {
      return new Date(date)?.getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .content-none {
    width: 100%;
    border-bottom: 2px dotted;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .styleText {
    word-wrap: break-word;
  }

  body,
  html {
    font-family: 'Times New Roman', Tinos, Times, serif !important;
  }
  .font {
    font-family: 'Times New Roman', Tinos, Times, serif !important;
  }
  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }
  p {
    margin-top: 0;
  }
  .text-italic {
    font-style: italic;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .top-0 {
    top: 0;
  }
  .left-0 {
    left: 0;
  }
  .px-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }
  .float-none {
    float: none;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block !important;
  }
  .flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
  .align-top {
    vertical-align: top;
  }
  .text-center {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-center {
    align-items: center;
  }
  .col-3 {
    width: 25% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .col-6 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .col-12 {
    width: 100% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .w-full {
    width: 100% !important;
  }
  .w-half {
    width: 50% !important;
  }
  .w-one-third {
    width: 33.333% !important;
  }
  .w-two-thirds {
    width: 66.667% !important;
  }
  .font-bold {
    font-weight: 700;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-sm {
    font-size: 0.92rem;
    line-height: 1.25rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-base {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }
  .pl-12 {
    padding-left: 3rem;
  }
  .pl-16 {
    padding-left: 4rem;
  }
  .pr-12 {
    padding-right: 3rem;
  }
  .pr-16 {
    padding-right: 4rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .ml-6 {
    margin-left: 1.5rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .mt-16 {
    margin-top: 4rem;
  }
  .mt-24 {
    margin-top: 6rem;
  }
  .ml-1 {
    margin-left: 0.25rem;
  }
  .mr-1 {
    margin-right: 0.25rem;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .hodo-table figure {
    margin: 0 0 1rem !important;
  }
  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }
  .hodo-table table thead th {
    text-transform: uppercase !important;
  }
  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }
}
</style>
<template>
  <div class="flex justify-center">
    <div class="text-gray-400 text-3xl mr-3 cursor-pointer fw-600 w-100">
      <div v-if="!showDetail">
        <div class="mb-0 text-blue-800 text-base flex justify-end">
          <div class="flex items-center">
            <span @click="handleOpenModal" class="fs-14 fw-700 text-grey-900 cursor-pointer">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2"
              >
                <path
                  d="M8.99984 0.666748C7.35166 0.666748 5.7405 1.15549 4.37009 2.07117C2.99968 2.98685 1.93158 4.28834 1.30084 5.81105C0.670115 7.33377 0.505087 9.00933 0.82663 10.6258C1.14817 12.2423 1.94185 13.7272 3.10728 14.8926C4.27272 16.0581 5.75758 16.8517 7.37409 17.1733C8.9906 17.4948 10.6662 17.3298 12.1889 16.6991C13.7116 16.0683 15.0131 15.0002 15.9288 13.6298C16.8444 12.2594 17.3332 10.6483 17.3332 9.00008C17.3332 7.90573 17.1176 6.8221 16.6988 5.81105C16.28 4.80001 15.6662 3.88135 14.8924 3.10752C14.1186 2.3337 13.1999 1.71987 12.1889 1.30109C11.1778 0.882296 10.0942 0.666748 8.99984 0.666748ZM8.99984 15.6667C7.6813 15.6667 6.39237 15.2758 5.29604 14.5432C4.19971 13.8107 3.34523 12.7695 2.84064 11.5513C2.33606 10.3331 2.20404 8.99269 2.46127 7.69948C2.71851 6.40627 3.35345 5.21839 4.2858 4.28604C5.21815 3.35369 6.40603 2.71875 7.69924 2.46151C8.99245 2.20428 10.3329 2.3363 11.5511 2.84088C12.7692 3.34547 13.8104 4.19995 14.543 5.29628C15.2755 6.39261 15.6665 7.68154 15.6665 9.00008C15.6665 10.7682 14.9641 12.4639 13.7139 13.7141C12.4636 14.9644 10.768 15.6667 8.99984 15.6667ZM12.3332 8.16675H9.83317V5.66675C9.83317 5.44573 9.74538 5.23377 9.5891 5.07749C9.43282 4.92121 9.22085 4.83342 8.99984 4.83342C8.77883 4.83342 8.56687 4.92121 8.41059 5.07749C8.25431 5.23377 8.16651 5.44573 8.16651 5.66675V8.16675H5.66651C5.44549 8.16675 5.23353 8.25455 5.07725 8.41083C4.92097 8.56711 4.83317 8.77907 4.83317 9.00008C4.83317 9.2211 4.92097 9.43306 5.07725 9.58934C5.23353 9.74562 5.44549 9.83342 5.66651 9.83342H8.16651V12.3334C8.16651 12.5544 8.25431 12.7664 8.41059 12.9227C8.56687 13.079 8.77883 13.1667 8.99984 13.1667C9.22085 13.1667 9.43282 13.079 9.5891 12.9227C9.74538 12.7664 9.83317 12.5544 9.83317 12.3334V9.83342H12.3332C12.5542 9.83342 12.7662 9.74562 12.9224 9.58934C13.0787 9.43306 13.1665 9.2211 13.1665 9.00008C13.1665 8.77907 13.0787 8.56711 12.9224 8.41083C12.7662 8.25455 12.5542 8.16675 12.3332 8.16675Z"
                  fill="#03267A"
                />
              </svg>
              Thêm Phiếu mới
            </span>
          </div>
        </div>

        <div v-loading="isLoading && !update.show" class="handleLoading">
          <el-table
            :data="listForms"
            :default-sort="{ prop: 'date', order: 'descending' }"
            style="width: 100%"
            row-class-name="text-black text-sm font-normal"
            header-cell-class-name="text-black text-base "
            class="cursor-pointer"
            @row-click="handleViewDetail"
          >
            <el-table-column :formatter="getDate" label="Ngày làm đơn" class="font-normal"></el-table-column>
            <el-table-column label="Bác sĩ Khám bệnh">
              <template slot-scope="scope">
                <span>{{ scope.row.examiner && scope.row.examiner.User && scope.row.examiner.User.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Bệnh viện chuyển đến">
              <template slot-scope="scope">
                <span>{{ scope.row && scope.row.transit_hospital_name }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="flex justify-content-end mt-3">
            <el-pagination
              v-if="listForms"
              background
              layout="prev, pager, next"
              :total="pagination.total"
              :page-size="parseInt(pagination.page_size)"
              @current-change="handleChangeCurrentPage"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div v-else>
        <Detail @reset="fetchData" />
      </div>
    </div>
    <ModalCreateGiayChuyenTuyen
      ref="ModalCreateGiayChuyenTuyen"
      :listDoctors="listDoctors"
      @create="handleCreateSuccess"
    ></ModalCreateGiayChuyenTuyen>

    <ModalSignPDF
      ref="ListChuyenTuyenModalSignPDF"
      containerIDProps="ListChuyenTuyenContainerSignPDFID"
      elementIDProps="ListChuyenTuyenSignPDFID"
      imageIDProps="ListChuyenTuyenSignPDFImageID"
      @onClose="fetchData"
    />
    <PdfViewer
      ref="ListChuyenTuyenPdfViewer"
      containerIDProps="ContainerListChuyenTuyenPDFViewerID"
      elementIDProps="ListChuyenTuyenPDFViewerID"
      @onClose="fetchData"
    />
  </div>
</template>
      
<script>
import moment from 'moment'
import Detail from './Detail.vue'
import ModalCreateGiayChuyenTuyen from './components/ModalCreateForm.vue'
import {
  ENVELOPE_DOCUMENT_TYPE,
  SIGN_ENVELOPE_STATUS
} from '../../../utils/constants'
import uploadS3File from '../../../utils/uploadS3File'
import SignatureRequest from '../../../api/request/SignatureRequest'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'

export default {
  name: 'GiayChuyenTuyen',
  components: { Detail, ModalCreateGiayChuyenTuyen, PdfViewer, ModalSignPDF },
  data () {
    return {
      showDetail: false,
      isLoading: false,
      update: {
        form: {
          name: '',
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
          pdf_url: '',
          pdf: null,
          anaesthetic_main: '',
          surgical_main: ''
        },
        show: false
      },
      listForms: [],
      pagination: {
        page_size: 10,
        page_num: 1,
        total: 0
      },
      listDoctors: []
    }
  },
  watch: {
    '$route.query.vId' () {
      this.handleOpenDetail()
    }
  },
  mounted () {
    if (this.$route.query.vId) this.showDetail = true
    this.fetchData()
    this.getClinicDoctors()
  },
  methods: {
    async getClinicDoctors () {
      try {
        const params = {
          page: 1,
          limit: 1000
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getClinicDoctor(this.$globalClinic?.id, params)
        this.listDoctors = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    handleOpenModal () {
      this.$refs.ModalCreateGiayChuyenTuyen.openModal(true)
      // window.$('#ModalCreateGiayChuyenTuyen').modal('show')
    },
    handleOpenDetail () {
      if (this.$route.query.vId) {
        this.showDetail = true
      } else {
        this.showDetail = false
        this.fetchData()
      }
    },
    async fetchData () {
      try {
        this.isLoading = true
        const params = {
          page_size: this.pagination.page_size,
          page_num: this.pagination.page_num,
          medical_record_id: this.$route.params?.id,
          order: 'desc',
          sort_by: 'created_at'
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getListTransitTickets(params)
        this.listForms = res?.data?.data || []
        this.pagination.total = res?.data?.page?.total
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleViewDetail (row) {
      this.isLoading = true

      const envelopeInfo = await this.openModalSignPDF(row)
      if (
        envelopeInfo &&
        envelopeInfo.status === SIGN_ENVELOPE_STATUS.completed
      ) {
        return envelopeInfo
      } else {
        this.$router
          .push({
            query: { tab: this.$route.query.tab, vId: row.id, type: 'view' }
          })
          .catch(_ => {})
      }
    },
    getDate (row) {
      if (row.created_at) {
        return row.created_at
          ? window.moment(row.created_at).format('HH:mm DD/MM/YYYY')
          : ''
      } else {
        return ''
      }
    },
    getDateTime (row) {
      return row.re_examination_time
        ? window
          .moment(row.re_examination_time)
          .subtract(7, 'hours')
          .format('HH:mm DD/MM/YYYY')
        : ''
    },
    handleChangeCurrentPage (page) {
      //
    },
    handleCreateSuccess () {
      window.$('#ModalCreateGiayChuyenTuyen').modal('hide')
      this.fetchData()
    },
    async openModalSignPDF (item) {
      const envelopeInfo = await this.handleCheckSignEnvelope(item)
      return envelopeInfo
    },
    async handleCheckSignEnvelope (item) {
      try {
        const documentID = item?.id
        const signEnvelope = await this.getSignEnvelopeByDocID(item)
        if (!signEnvelope) return

        const signatories = signEnvelope?.envelope_signatories?.map(item => ({
          ...item,
          user: item?.user_info,
          user_id: item?.user_id
        }))

        const signerInfo = signEnvelope?.envelope_signatories?.find(
          item => item?.user_id === this.$user?.id
        )

        const s3Url = await uploadS3File.getLinkVideoAWS(
          signEnvelope?.document_url
        )

        if (signerInfo?.status === 2) {
          this.$nextTick(() => {
            this.$refs.ListChuyenTuyenPdfViewer.handleOpen(
              s3Url,
              ENVELOPE_DOCUMENT_TYPE.TRANSIT_FORM,
              documentID,
              false,
              false
            )
          })
        } else {
          const hasPermissionSignatory = signatories?.some(
            item => item?.user_id === this.$user?.id
          )

          if (!hasPermissionSignatory) {
            this.$toast.open({
              message: 'Bạn không có quyền ký tài liệu này',
              type: 'error'
            })
            return
          }
          // this.$nextTick(() => {
          //   this.$refs.ListChuyenTuyenModalSignPDF.handleOpen(
          //     s3Url,
          //     ENVELOPE_DOCUMENT_TYPE.TRANSIT_FORM,
          //     documentID,
          //     signerInfo?.user_id,
          //     signatories || [],
          //     signEnvelope
          //   )
          // })
        }

        return signEnvelope
      } catch (error) {
        console.log(error)
      }
    },
    async getSignEnvelopeByDocID (item) {
      try {
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: item?.id,
          doc_type: ENVELOPE_DOCUMENT_TYPE.TRANSIT_FORM
        })

        return response.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>